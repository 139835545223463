.hero-container {
    position: relative; 
    height: 100vh;
    width: 100%;
    color: white;
    margin: 0;
    z-index: 0;
}

.upper-wrapper {
    position: relative;
    top: 0%;
    background-color: var(--backgroundColor);
    width: 100vw;
    height: 10vh;
    padding: 0;
    display: flex;
    align-items: center;
}/* 
.upper-wrapper img {
    position: relative;
    left: 0%;
    width: auto;
    height: 80%; 
    margin-right: 90vw;
} */
@media screen and (max-width: 1000px) {
    .upper-wrapper {
        width: 100vw;
        height: 10svh;
    }/* 
    .upper-wrapper img{
        margin-right: 70vw;
    } */
}
.title-container {
    overflow: hidden;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
}
.title-container h1{
    font-family: var(--fontFamily-main);
}

.overlay {
    position: absolute;
    top: 0vh;
    left: 0%;
    height: 100vh;
    width: 70svw;
    z-index: 1;
    background: linear-gradient(to right, rgba(0,0,0,0.7), rgba(0,0,0,0))
}

@media screen and (max-width: 1000px) {
    .overlay {
        top: 0; 
        left: 50%;
        transform: translateX(-50%);
        height: 60svh;
        width: 100svw;
        background: linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0))
    }
}
.slider-main {
    position: absolute;
    top: 30vh;
    left: 10%;
    z-index: 58;
    width: 80%;      
    text-align: center; 
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .slider-main p{
    position: relative;
    width: 50vw;
    font-size: 3rem;
    margin: 0;
    color:var(--white);
    font-family: var(--fontFamily-main);
    text-shadow: 1px 1px #000;
  }
  .slider-main img{
    position: relative;
    bottom: 1rem; 
    width: auto;
    height: 10rem; 
    margin: auto;
    
}
@media screen and (min-width: 1930px) {
    .slider-main p{
        font-size: 5rem;
    }
    .slider-main img{
        height: 12rem; 
    }
}
  @media screen and (max-width: 1000px) {
    .slider-main {
      top: 25svh;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      flex-direction: column;
    }
    .slider-main p{
      font-size: 2rem;
      width: auto;
    }
    
    .slider-main img{
        position: relative;
        top: 1rem; 
        width: auto;
        height: 6rem; 
        margin: auto;
    }
  }