.storitve-wrapper {
    width: 100%;
    height: auto;
    padding: 3rem 6rem;
}

.storitve-wrapper h1 {
    font-size: var(--fontSizePc);
    font-family: var(--fontFamily-main);
    font-weight: 700;
    color: var(--mainColor);
    margin: 2rem 0;
}
@media screen and (max-width: 1000px) {
    .storitve-wrapper h1{
        font-size: var(--fontSizePhone);
    }
}

@media screen and (max-width: 1000px) {
    .storitve-wrapper {
        padding: 0.3rem;
    }
}

.storitve-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
}

.storitve-item {
    flex-grow: 4;
    height: 20vw;
    width: 20vw;
    padding: 0.3rem;
    border-radius: 5px;
    text-align: center;
    margin: 1rem;
    perspective: 1000px;
    position: relative;
    overflow: hidden; 
    font-family: var(--fontFamily-main);
}

@media screen and (max-width: 1000px) {
    .storitve-item {
        width: 45vw;
        height: 45vw;
        flex-grow: 2;
        font-size: 0.75rem;
        margin: 0.2rem;
    }
}

.storitve-content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    color: var(--mainColor);
}


.card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px; /* To match the storitve-item border-radius */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform 0.8s;
}

.card-back {
    transform: rotateY(180deg);
    color: white;
}

.card-back img {
    width: 90%;
    height: 90%;
    object-fit: cover;
}
.card-back h2 {
    position: absolute;
    width: 90%;
    bottom: 22%;
    background-color: rgba(0, 0, 0, 0.24);
}
.card-front {
    padding: 2rem 0;
}
.card-front img {
    height: 5rem;
    width: 5rem;
}

@media screen and (max-width: 1000px) {
    .card-front img {
        height: 3rem;
        width: 3rem;
    }
    
}

