.container {
    position: fixed;
    left: 0%;
    width: 100%;
    height: 10vh;
    color: beige;
    z-index: 2;
    padding: 0;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
}

.container.hidden {
    transform: translateY(-100%);
}

nav {
    display: flex;
    z-index: 11;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    font-family: var(--fontFamily-main);
}

nav .menu-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    z-index: 11;
}

nav .menu-container .menu-icon {
    display: none;
}

nav .menu-container .desktop-menu {
    display: none;
}

nav .menu-container .desktop-menu li {
    display: inline;
    margin-right: 3rem;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 2rem;
    color: white;
    text-shadow: 0px 0.1rem var(--mainColor);
}

nav .menu-container .desktop-menu li:hover {
    color: var(--mainColor);
}
@media screen and (min-width: 1930px) {
    nav .menu-container .desktop-menu li {
        font-size: 3rem;
    }
}
@media screen and (max-width: 1000px) {
    nav .menu-container {
        margin-left: 70vw;
    }
    nav .menu-container .menu-icon {
        display: block;
        text-transform: uppercase;
        font-size: 1.5rem;
        text-shadow: 0px 0.1rem var(--mainColor);
    }
}

@media screen and (min-width: 1000px) {
    nav .menu-container .menu-icon {
        display: none;
    }
    nav .menu-container .desktop-menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
    }
}

.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(8px);
    background-color: rgba(0,0,0,0.2);
    z-index: 11;
}

.mobile-menu button {
    align-self: flex-end;
    margin: 35px 45px 0 0;
    outline: none;
    border: none;
    background-color: transparent;
    text-transform: uppercase;
    font-family: inherit;
    font-size: 2rem;
    color: white;
    text-shadow: 0px 0.1rem var(--mainColor);
}

.mobile-menu button:hover {
    cursor: pointer;
}

.mobile-menu ul {
    list-style: none;
    margin-top: 40px;
    z-index: 11;
}

.mobile-menu ul li {
    margin: 20px 0;
    overflow-y: hidden;
    user-select: none;
}

.mobile-menu ul li div {
    text-align: center;
    text-transform: uppercase;
    font-size: 2rem;
    text-decoration: none;
    color: #fff;
    text-shadow: 0px 0.1rem var(--mainColor);
    list-style: none;
    letter-spacing: 0.1rem;
    font-weight: 500;
    white-space: nowrap;
    margin: 0 0;
}

.mobile-menu ul li:hover {
    font-style: italic;
    cursor: pointer;
}

.mobile-menu .contact {
    margin-top: 80px;
    display: flex;
}

.mobile-menu .contact h5 {
    font-weight: normal;
}

.mobile-menu .contact h5:first-of-type {
    margin-right: 40px;
}

.img-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 100%;
}

.img-container img {
    height: 700px;
    pointer-events: none;
}
