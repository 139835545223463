.slider-show {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
  margin: 0;
}
@media screen and (max-width: 1000px) {
  .slider-show {
      width: 100%;
      height: 100svh;
      top: 0;
  }
}
.image {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1000px) {
  .image{
      width: 100%;
      height: 100%;
  }
}
.slider-text {
  position: absolute;
  bottom: 3rem;
  right: 0%;
  transform: none;
  width: 30%; 
  height: 3rem; 
  justify-content: center;
  align-items: center;    
  text-align: center; 
  z-index: 0;
  background-color: var(--mainColor);
}
.slider-text p{
  height: 100%;
  align-content: center;
  font-size: 1.4rem;
  color:var(--white);
  font-family: var(--fontFamily-main);
  text-shadow: 1px 1px #000;
}
@media screen and (max-width: 1000px) {
  .slider-text{
      left: 50%;
      transform: translateX(-50%);
  }
}
.slider-controls {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 30%; 
  height: 3rem;  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.slider-controls button {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background-color: black;
  border: none;
  color: var(--mainColor);
  z-index: 100;
  margin: 0;
  font-family: var(--fontFamily-main);
  font-size: 1rem;
  justify-content: center;
  align-items: center;
} 

 .slider-controls button:first-of-type {
  background-color: black;
  padding: 1rem 1rem 1rem 0;
}

.slider-controls button:last-of-type {
  padding: 1rem 0 1rem 1rem;
  background-color: rgba(0,0,0,0.6);
} 
@media screen and (max-width: 1000px) {
  .slider-text{
    width: 100%; 
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .slider-controls {
    bottom: 0;
    width: 100%;
  }
}