.footer-wrapper {
    height: auto;
    width: 100vw;
    background-color: var(--backgroundColor);
    color: var(--mainColor);
}
@media screen and (max-width: 1000px) {
    .footer-wrapper{
        height: auto;
    }
}
.footer-container {
    height: auto;
    width: 70vw;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
}
@media screen and (max-width: 1000px) {
    .footer-container{
        height: auto;
        width: 100vw;
        flex-direction: column;
        padding: 2rem 0rem;
    }
}
.footer-contact{
    display: flex;
    flex-direction: column;
    width: 20%;
}
.footer-contact p,.footer-contact a{
    font-family: var(--fontFamily-text);
    color: var(--mainColor);
    text-decoration: none;
}
@media screen and (max-width: 1000px) {
    .footer-contact{
        width: 100%;
    }
}
.footer-maintext {
    width: 60%;
    font-size: 2rem;
    font-family: var(--fontFamily-main);
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
}
.footer-maintext h3{
    width: 80%;
    font-size: 2rem;
}
.footer-maintext p{
    font-family: var(--fontFamily-text);
    font-size: 1rem;
}
.footer-maintext img{
    height: auto;
    width: 20%;
}
@media screen and (max-width: 1000px) {
    .footer-maintext{
        width: 90%;
    }
    .footer-maintext h3{
        width: 100%;
        font-size: 1.5rem;
    }
    .footer-maintext img{
        height: auto;
        width: 50%;
    }
}

.social-media{
    display: flex;
    gap: 1rem;
    width: 70%;
    flex-direction: column;
    align-items: center;
    padding: 0 0;
    margin: auto auto;
    font-family: var(--fontFamily-main);
    font-size: 1.2rem;
    justify-content: space-between;
}
.social-media button {
    background-color: transparent;
    border: 1px solid var(--mainColor);
    border-radius: 1.5rem;
    height: 3rem;
    width: 3rem ;
    margin: auto;
}
.social-media button:hover {
    background-color: white;
    color: var(--eerie-black-1);
}
.social-media i{
    color: var(--mainColor);
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0;
    position: relative;
    top: 0.4rem;
    left: 50%;
    transform: translate(-50%);
    font-size: 2.2rem;
}
.social-media i:hover {
    color: var(--eerie-black-1);
}
.social-media a{
    margin: 0.5rem;
}
.footer-copyrights {
    position: relative;
    bottom: 0%;
    height: 1.5rem;
    width: 100%;    
    color: var(--mainColor);
    margin: 0;
}
.footer-copyrights p{
    width: 50%;   
    height: auto;
    font-size: 1rem;
    font-family: var(--fontFamily-text);
}
@media screen and (max-width: 1000px) {
    .footer-copyrights{
        width: 100%;
        flex-direction: column;
        height: 3rem;
    }
    .footer-copyrights p{
        width: 90%;
        height: 3rem;
    }
}

.footer-wrapper hr{
    position: relative;
    left: 0;
    bottom: 0;
    width: 80%;
    color: var(--mainColor);
    margin: 1rem auto;
}
@media screen and (min-width: 1000px) {
    .hidebr{
        display: none;
    }
}
